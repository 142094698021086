/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMall } from "../../../../../app/modules/Auth/_redux/authCrud";
import clsx from "clsx";
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux";
import { getSrc } from "../../../../_helpers";

export function MallDropdown() {
  const { mallDetails } = useSelector((state) => state.auth, shallowEqual);
  const [mallInformation, setMallInfo] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getMall().then((response) => {
      setMallInfo(response.data.response);
      dispatch(auth.actions.setMallDetails(response.data.response[0]));
    });
  }, []);

  useEffect(() => {
    getMall().then((response) => {
      setMallInfo(response.data.response);
    });
  }, [mallDetails]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <div>
          <span>
            {mallDetails?.logo && (
              <img
                style={styles.logo}
                alt="logo"
                src={mallDetails?.logo}
              />
              // <img
              //   style={styles.logo}
              //   alt="logo"
              //   src={mallDetails?.logo}
              // />
            )}
          </span>
          <span className="navi-text menu-text">
            {mallDetails?.name}
            <small className="text-light">&nbsp;&#9660;</small>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover">
          {mallInformation?.map((details) => (
            <li
              key={details?.name}
              className="navi-item mall-dropdown-item border-bottom border-dark p-4 h6 m-0"
              onClick={() => {
                dispatch(auth.actions.setMallDetails(details));
              }}
            >
              <span className="navi-text">{details?.name}</span>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const styles = {
  logo: {
    maxWidth: "30px",
    marginRight: "10px",
    marginLeft: "15px",
  },
};
